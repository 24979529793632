import { LANGUAGE_CODE } from 'loudly-shared/common/constants';

export {
  LANGUAGE_CODE,
  LANGUAGE_MAPPING,
  USER_ROLE,
  USER_GENDER,
  SCHOOL_TYPE,
  ASSIGNMENT_MODE,
  ASSIGNMENT_COMPETENCE_ID,
  PARTY_ROOM_STATE,
  BRAND,
  MEDIA_TYPE,
} from 'loudly-shared/common/constants';

export const APP_LOCALES = [LANGUAGE_CODE.EN, LANGUAGE_CODE.NL];

export const APP_LOCALE_DEFAULT = LANGUAGE_CODE.EN;

export const CONTENT_EXCEL_SHEET_NAME = 'LoudlyTemplate.xlsx';

export const CONTENT_EXCEL_SHEET_DIR = 'assets/templates/';
