export const USERS_COLLECTION = 'users';
export const USER_RECORD_COLLECTION = 'userRecord';

export const SCHOOLS_COLLECTION = 'schools';
export const LICENSES_COLLECTION = 'licenses';

export const METHODS_COLLECTION = 'methods';
export const CHAPTERS_COLLECTION = 'chapters';
export const WORDLISTS_COLLECTION = 'wordlists';
export const QUESTIONS_COLLECTION = 'questions';

export const USER_RECORD_GLOBAL_COLLECTION = 'global';

export const USER_RECORD_STATISTICS_DOCUMENT = 'statistics';

export const PARTY_ROOMS_COLLECTION = 'partyRooms';

export const ORDERS_COLLECTION = 'orders';

export const STUDENTS_COLLECTION = 'students';

export const STUDENT_RESULTS_COLLECTION = 'results';

export const GROUPS_COLLECTION = 'groups';

export const GROUPS_RESULTS_COLLECTION = 'results';
