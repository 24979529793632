import { fetchQuestionCollection } from 'utils/firebase/service';

/**
 * Compute party room player ranks
 * @param {PartyRoom} partyRoom
 * @returns {Object<ID, PartyRoomPlayerRank>}
 */
export async function computePartyRoomRanks(partyRoom) {
  const questions = await fetchQuestionCollection(partyRoom);

  const questionById = questions.reduce(
    (acc, question) => ({ ...acc, [question.id]: question }),
    {},
  );

  let playerRankById = Object.keys(partyRoom.playerById).map(playerId => {
    const playerAnswers = Object.entries(
      partyRoom.playerAnswerByPlayerIdByQuestionId[playerId] || {},
    );

    // computePartyRoomQuestionAnswers

    const {
      correctAnswersCount,
      wrongAnswersCount,
      score,
    } = playerAnswers.reduce(
      (acc, [questionId, playerAnswer]) => {
        const playerAnswerIsCorrect = playerAnswer.answerIsCorrect;

        // eslint-disable-next-line no-use-before-define
        const playerScore = computePartyRoomQuestionPlayerAnswerScore(
          partyRoom,
          questionById[questionId],
          playerId,
        );

        return {
          correctAnswersCount:
            acc.correctAnswersCount + (playerAnswerIsCorrect ? 1 : 0),
          wrongAnswersCount:
            acc.wrongAnswersCount + (playerAnswerIsCorrect ? 0 : 1),
          score: acc.score + playerScore,
        };
      },
      {
        correctAnswersCount: 0,
        wrongAnswersCount: 0,
        score: 0,
      },
    );

    return {
      playerId,
      correctAnswersCount,
      wrongAnswersCount,
      score,
      rank: null,
    };
  });

  playerRankById = playerRankById.sort((a, b) => b.score - a.score);

  playerRankById = playerRankById.map((item, index) => ({
    ...item,
    rank: index + 1,
  }));

  playerRankById = playerRankById.reduce(
    (acc, item) => ({ ...acc, [item.playerId]: item }),
    {},
  );

  return playerRankById;
}

/**
 * Compute party room score average
 * @param {PartyRoomPlayerRank[]} playerRanks
 * @returns {number}
 */
export function computePartRoomScoreAverage(playerRanks) {
  return (
    playerRanks.reduce((acc, item) => acc + item.score, 0) / playerRanks.length
  );
}

/**
 * Compute party room all answers for question
 * @param {PartyRoom} partyRoom
 * @param {Question} question
 * @returns {Object} result
 * @returns {PartyRoomPlayer[]} result.playerAnswers
 * @returns {any[]} result.answerValues
 * @returns {number} result.answerCount
 * @returns {Object<string, number>} result.answerCountByValue
 */
export function computePartyRoomQuestionAnswers(partyRoom, question) {
  const playerAnswers = Object.values(
    partyRoom.playerAnswerByPlayerIdByQuestionId,
  ).reduce((acc, playerAnswerByQuestionId) => {
    const playerAnswer = playerAnswerByQuestionId[question.id];
    if (playerAnswer) {
      return [...acc, playerAnswer];
    }
    return acc;
  }, []);

  const answerCountByValue = playerAnswers.reduce(
    (acc, playerAnswer) => ({
      ...acc,
      [playerAnswer.answer]: 1 + (acc[playerAnswer.answer] || 0),
    }),
    {},
  );

  const answerValues = playerAnswers
    .reduce(
      (acc, playerAnswer) =>
        acc.includes(playerAnswer.answer) ? acc : [...acc, playerAnswer.answer],
      [],
    )
    .sort((a, b) => answerCountByValue[b] - answerCountByValue[a]);

  const answerCount = playerAnswers.length;

  return {
    playerAnswers,
    answerValues,
    answerCount,
    answerCountByValue,
  };
}

/**
 * Compute party room player answer for question
 * @param {PartyRoom} partyRoom
 * @param {Question} question
 * @param {ID} playerId
 * @returns {Object} result
 * @returns {PartyRoomPlayerAnswer} result.playerAnswer
 * @returns {boolean} result.playerAnswerIsCorrect
 * @returns {number} result.playerAnswerTime
 */
export function computePartyRoomQuestionPlayerAnswer(
  partyRoom,
  question,
  playerId,
) {
  const playerAnswer = partyRoom.playerAnswerByPlayerIdByQuestionId[playerId]
    ? partyRoom.playerAnswerByPlayerIdByQuestionId[playerId][question.id] ||
      null
    : null;

  const playerAnswerIsCorrect = !!playerAnswer && playerAnswer.answerIsCorrect;

  let playerAnswerTime = 0;
  if (playerAnswerIsCorrect) {
    playerAnswerTime =
      playerAnswer.timestamp.seconds -
      partyRoom.questionTimestampById[question.id].seconds;
    if (playerAnswerTime > partyRoom.answerTimeLimit) {
      // player is probably sheeting by reloading the page
      playerAnswerTime = -1;
    }
  }

  return {
    playerAnswer,
    playerAnswerIsCorrect,
    playerAnswerTime,
  };
}

/**
 * Compute party room player answer score for question
 * @param {PartyRoom} partyRoom
 * @param {Question} question
 * @param {ID} playerId
 * @returns {number}
 */
export function computePartyRoomQuestionPlayerAnswerScore(
  partyRoom,
  question,
  playerId,
) {
  const {
    playerAnswerIsCorrect,
    playerAnswerTime,
  } = computePartyRoomQuestionPlayerAnswer(partyRoom, question, playerId);

  let playerAnswerScore = 0;

  if (playerAnswerIsCorrect) {
    if (partyRoom.scoreIsTimeDependent) {
      playerAnswerScore = 100 + (partyRoom.answerTimeLimit - playerAnswerTime);
    } else {
      playerAnswerScore = 100;
    }
  }

  return playerAnswerScore;
}
