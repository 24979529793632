/* eslint-disable max-classes-per-file */

import React from 'react';

import * as PropTypes from 'utils/proptypes';

import './stateDefinitions';

/**
 * Global State Manager
 */
export const GlobalStateManager = {};

/**
 * Global State Storage
 */
export const GlobalStateStorage = {};

if (process.env.NODE_ENV === 'development') {
  global.$GlobalStateManager = GlobalStateManager;
  global.$GlobalStateStorage = GlobalStateStorage;
}

/**
 * Global State Context
 * @type {React.Context<GlobalState>}
 */
export const GlobalStateContext = React.createContext();

GlobalStateContext.displayName = 'GlobalState';

export const {
  Provider: GlobalStateProvider,
  Consumer: GlobalStateConsumer,
} = GlobalStateContext;

/**
 * Global state factory for generating HOC that attaches state selection as prop
 * @template S
 * @param {(state: GlobalState, ownProps?: object) => S} selector selects which state to expose as props to connected component
 * @returns {React.Component<S>} connected component
 */
export const GlobalStateConnector = (
  selector = state => state,
) => Component => {
  const WrappedComponent = props => (
    <GlobalStateConsumer>
      {state => <Component {...props} {...selector(state, props)} />}
    </GlobalStateConsumer>
  );

  WrappedComponent.displayName = `withGlobalState(${Component.displayName ||
    Component.name})`;

  return WrappedComponent;
};

/**
 * State Manager
 * @template T
 */
class StateManagerImpl {
  /**
   * State name
   * @type {string}
   */
  name = null;

  /**
   * State initial value
   * @type {Function}
   */
  getInitialState = null;

  /**
   * State container
   * @type {React.Component}
   */
  container = null;

  /**
   * State change listeners
   * @type {((prevState: T) => void)[]}
   */
  listeners = [];

  /**
   * @param {string} name
   * @param {() => T} getInitialState
   */
  constructor(name, getInitialState) {
    this.name = name;
    this.getInitialState = getInitialState;
    this.getState = this.getState.bind(this);
    this.setState = this.setState.bind(this);
  }

  /**
   * @param {React.Component} container
   */
  setup(container) {
    this.container = container;

    this.setState(this.getInitialState);
  }

  /**
   * @returns {T}
   */
  getState() {
    return this.container.state[this.name];
  }

  /**
   * @param {((state: T) => void)|T} stateChange
   * @returns {Promise<void>}
   */
  setState(stateChange) {
    let prevState = null;

    const updater = state => {
      prevState = state[this.name];

      const stateUpdate =
        typeof stateChange === 'function'
          ? stateChange(state[this.name])
          : stateChange;

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(
          'STATE',
          this.name,
          JSON.parse(JSON.stringify(stateUpdate)),
        );
      }

      return {
        [this.name]: {
          ...state[this.name],
          ...stateUpdate,
        },
      };
    };

    return new Promise(resolve =>
      this.container.setState(updater, () => {
        resolve();
        this.listeners.forEach(listener => listener(prevState));
      }),
    );
  }

  /**
   * @param {((state: T) => void)|T} stateChange
   * @returns {Promise<void>}
   */
  setStateAsync(stateChange) {
    return new Promise(resolve => this.setState(stateChange, resolve));
  }

  /**
   * @returns {Promise<void>}
   */
  resetState() {
    return this.setState(this.getInitialState());
  }

  /**
   * Subscribe to state change
   * @param {(prevState: T) => void} listener
   * @returns {Function}
   */
  subscribe(listener) {
    this.listeners.push(listener);
    return () => this.unsubscribe(listener);
  }

  /**
   * Unsubscribe from state change
   * @param {Function} listener
   */
  unsubscribe(listener) {
    const index = this.listeners.findIndex(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }
}

/**
 * Create state management helpers
 *
 * @template T
 * @template S
 *
 * @param {string} name
 * @param {() => T} getInitialState provides initial state
 * @param {S} StatePropTypes
 */
export function createState(name, getInitialState, StatePropTypes) {
  /**
   * State Storage
   */
  const StateStorage = {};

  GlobalStateStorage[name] = StateStorage;

  /**
   * State Manager
   * @type {StateManagerImpl<T>}
   */
  const StateManager = new StateManagerImpl(name, getInitialState);

  GlobalStateManager[name] = StateManager;

  /**
   * State Consumer
   */
  const StateConsumer = ({ children }) => (
    <GlobalStateConsumer>{state => children(state[name])}</GlobalStateConsumer>
  );

  StateConsumer.displayName = `${name}State.Consumer`;

  StateConsumer.propTypes = {
    children: PropTypes.func.isRequired,
  };

  /**
   * State factory for generating HOC that attaches state selection as prop
   * @template S
   * @param {(state: T, ownProps?: object) => S} selector selects which state to expose as props to connected component
   * @returns {React.Component<S>} connected component
   */
  const StateConnector = (selector = state => state) => Component => {
    const WrappedComponent = props => (
      <StateConsumer>
        {state => <Component {...props} {...selector(state, props)} />}
      </StateConsumer>
    );

    WrappedComponent.displayName = `with${name}State(${Component.displayName ||
      Component.name})`;

    return WrappedComponent;
  };

  return {
    Storage: StateStorage,
    Manager: StateManager,
    Consumer: StateConsumer,
    Connector: StateConnector,
    PropTypes: StatePropTypes,
  };
}

/**
 * Create state storage
 *
 * @param {string} stateName
 * @param {string} stateField
 * @param {Storage=localStorage} [provider] `localStorage` or `sessionStorage`
 */
export function createStorage(
  stateName,
  stateField,
  provider = window.localStorage,
) {
  const key = `${stateName}.${stateField}`;

  const storage = {
    get() {
      try {
        return JSON.parse(provider.getItem(key));
      } catch (e) {
        return null;
      }
    },
    set(value) {
      provider.setItem(key, JSON.stringify(value));
    },
    clear() {
      provider.removeItem(key);
    },
  };

  GlobalStateStorage[stateName] = GlobalStateStorage[stateName] || {};

  GlobalStateStorage[stateName][stateField] = storage;

  return storage;
}
