import {
  METHODS_COLLECTION,
  CHAPTERS_COLLECTION,
  WORDLISTS_COLLECTION,
  QUESTIONS_COLLECTION,
  STUDENTS_COLLECTION,
  GROUPS_COLLECTION,
} from 'loudly-shared/firebase/collections';
import {
  methodSorter,
  chapterSorter,
  wordlistSorter,
  studentSorter,
  groupSorter,
} from 'loudly-shared/common/data';
import { getDoc, collection, doc, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';

export function getRecordFromDocument(document) {
  return document?.exists()
    ? {
        id: document.id,
        ...document.data(),
      }
    : null;
}

/**
 * fetches method collection
 *
 * @returns {Promise<Method[]>}
 */
export async function fetchMethodCollection() {
  const ref = collection(firestore, METHODS_COLLECTION);
  const result = await getDocs(ref);

  const data = result.docs.map(getRecordFromDocument).sort(methodSorter);

  return data;
}

/**
 * fetches method document with `methodId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @returns {Promise<Method>}
 */
export async function fetchMethodDocument({ methodId }) {
  const ref = doc(firestore, `${METHODS_COLLECTION}/${methodId}`);
  const result = await getDoc(ref);

  const data = getRecordFromDocument(result);

  return data;
}

/**
 * fetches chapter collection for a given `methodId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @returns {Promise<Chapter[]>}
 */

export async function fetchChapterCollection({ methodId }) {
  const ref = collection(
    firestore,
    `${METHODS_COLLECTION}/${methodId}/${CHAPTERS_COLLECTION}`,
  );
  const result = await getDocs(ref);

  const data = result.docs.map(getRecordFromDocument).sort(chapterSorter);

  return data;
}

/**
 * fetches chapter document with `chapterId` for a given `methodId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @param {ID} target.chapterId
 * @returns {Promise<Chapter>}
 */
export async function fetchChapterDocument({ methodId, chapterId }) {
  const ref = doc(
    firestore,
    `${METHODS_COLLECTION}/${methodId}/${CHAPTERS_COLLECTION}/${chapterId}`,
  );
  const result = await getDoc(ref);

  const data = getRecordFromDocument(result);

  return data;
}

/**
 * fetches wordlist collection for a given `methodId` and `chapterId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @param {ID} target.chapterId
 * @returns {Promise<Wordlist[]>}
 */

export async function fetchWordlistCollection({ methodId, chapterId }) {
  const ref = collection(
    firestore,
    `${METHODS_COLLECTION}/${methodId}/${CHAPTERS_COLLECTION}/${chapterId}/${WORDLISTS_COLLECTION}`,
  );
  const result = await getDocs(ref);

  const data = result.docs.map(getRecordFromDocument).sort(wordlistSorter);

  return data;
}

/**
 * fetches wordlist document with `wordlistId` for a given `methodId` and `chapterId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @param {ID} target.chapterId
 * @param {ID} target.wordlistId
 * @returns {Promise<Wordlist>}
 */
export async function fetchWordlistDocument({
  methodId,
  chapterId,
  wordlistId,
}) {
  const ref = doc(
    firestore,
    `${METHODS_COLLECTION}/${methodId}/${CHAPTERS_COLLECTION}/${chapterId}/${WORDLISTS_COLLECTION}/${wordlistId}`,
  );

  const result = await getDoc(ref);

  const data = getRecordFromDocument(result);

  return data;
}

/**
 * fetches question collection for a given `methodId`, `chapterId`, and `wordlistId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @param {ID} target.chapterId
 * @param {ID} target.wordlistId
 * @returns {Promise<Question[]>}
 */

export async function fetchQuestionCollection({
  methodId,
  chapterId,
  wordlistId,
}) {
  const ref = collection(
    firestore,
    `${METHODS_COLLECTION}/${methodId}/${CHAPTERS_COLLECTION}/${chapterId}/${WORDLISTS_COLLECTION}/${wordlistId}/${QUESTIONS_COLLECTION}`,
  );
  const result = await getDocs(ref);

  const data = result.docs.map(getRecordFromDocument);

  return data;
}

/**
 * fetches question document with `questionId` for a given `methodId`, `chapterId`, and `wordlistId`
 *
 * @param {Object} target
 * @param {ID} target.methodId
 * @param {ID} target.chapterId
 * @param {ID} target.wordlistId
 * @param {ID} target.questionId
 * @returns {Promise<Question>}
 */
export async function fetchQuestionDocument({
  methodId,
  chapterId,
  wordlistId,
  questionId,
}) {
  const ref = doc(
    firestore,
    `${METHODS_COLLECTION}/${methodId}/${CHAPTERS_COLLECTION}/${chapterId}/${WORDLISTS_COLLECTION}/${wordlistId}/${QUESTIONS_COLLECTION}/${questionId}`,
  );
  const result = await getDoc(ref);

  const data = getRecordFromDocument(result);

  return data;
}

/**
 * fetches student collection
 *
 * @returns {Promise<Student[]>}
 */
export async function fetchStudentCollection() {
  const ref = collection(firestore, STUDENTS_COLLECTION);
  const result = await getDocs(ref);

  const data = result.docs.map(getRecordFromDocument).sort(studentSorter);

  return data;
}

/**
 * fetches group collection
 *
 * @returns {Promise<Group[]>}
 */
export async function fetchGroupCollection() {
  const ref = collection(firestore, GROUPS_COLLECTION);

  const result = await getDocs(ref);

  const data = result.docs.map(getRecordFromDocument).sort(groupSorter);

  return data;
}
