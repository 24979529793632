import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { color } from 'styles';

const circleFadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
`;

const Circle = ({ rotate, delay }) => {
  const CirclePrimitive = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    ${rotate &&
      css`
        transform: rotate(${rotate}deg);
      `}

    &::before {
      animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;
      background-color: ${color.ACCENT_SECONDARY};
      border-radius: 100%;
      content: '';
      display: block;
      height: 15%;
      margin: 0 auto;
      width: 15%;

      ${delay &&
        css`
          animation-delay: ${delay}s;
        `};
    }
  `;
  return <CirclePrimitive />;
};

Circle.defaultProps = {
  delay: null,
  rotate: null,
};

Circle.propTypes = {
  delay: PropTypes.number,
  rotate: PropTypes.number,
};

export default Circle;
