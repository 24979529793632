export const LANDING_ROUTE = '/';
export const SIGNIN_ROUTE = '/signin';
export const FORGET_PASSWORD_ROUTE = '/forget-password';
export const REGISTRATION_ROUTE = '/registration';
export const CONFIRMATION_ROUTE = '/confirmation';
export const VERIFY_ACCOUNT_ROUTE = '/verify-account';

export const HOME_ROUTE = '/methods';
export const CHAPTERS_ROUTE = '/chapters/:methodId';
export const ASSIGNMENT_PREFERENCES_ROUTE = '/assignment-preferences';
export const PAYMENTS_ROUTE = '/payments';

export const PRACTICE_MODE_ROUTE = '/practice';
export const SOCIAL_MODE_ROUTE = '/social';
export const SOCIAL_MODE_REDIRECT = '/party';

export const PUBLISHERS_ROUTE = '/publishers';
export const SUPPORT_ROUTE = '/support';
export const PROFILE_ROUTE = '/profile';
export const MANAGER_ROUTE = '/manager';
