import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, getDownloadURL, ref } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// Load configuration, it is possible to set some defaults here as well.
const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
} = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const firebase = initializeApp(config);

const auth = getAuth(firebase);

const firestore = getFirestore(firebase);

const storage = getStorage(firebase);

const functions = getFunctions(
  firebase,
  process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION || 'us-central1',
);

export { firebase, auth, firestore, storage, functions };

export async function getStorageUrl(path) {
  const url = await getDownloadURL(ref(storage, path));
  return url;
}
