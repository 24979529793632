/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as routes from 'routes';
import AppTheme from 'components/AppTheme';
import LoadingIndicator from 'components/LoadingIndicator';
import GlobalStyle from 'styles/global';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const LandingPage = lazy(() => import('containers/LandingPage/index'));
const SigninPage = lazy(() => import('containers/SigninPage'));
const ForgetPasswordPage = lazy(() => import('containers/ForgetPasswordPage'));
const HomePage = lazy(() => import('containers/HomePage'));
const RegistrationPage = lazy(() => import('containers/RegistrationPage'));
const ConfirmationPage = lazy(() => import('containers/ConfirmationPage'));
const VerificationPage = lazy(() => import('containers/VerificationPage'));
const NotFoundPage = lazy(() => import('containers/NotFoundPage'));
const ChaptersPage = lazy(() => import('containers/ChaptersPage'));
const AssignmentPreferences = lazy(() =>
  import('containers/AssignmentPreferences'),
);
const Payments = lazy(() => import('containers/Payments'));
const PracticeMode = lazy(() => import('containers/PracticeMode'));
const SocialMode = lazy(() => import('containers/SocialMode'));
const SupportPage = lazy(() => import('containers/SupportPage'));
const PublishersPage = lazy(() => import('containers/PublishersPage'));
const ProfilePage = lazy(() => import('containers/ProfilePage'));

const App = ({ intl }) => {
  document.documentElement.lang = intl.locale;
  return (
    <React.Fragment>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route exact path={routes.LANDING_ROUTE} component={LandingPage} />
          <Route exact path={routes.SIGNIN_ROUTE} component={SigninPage} />
          <Route
            exact
            path={routes.FORGET_PASSWORD_ROUTE}
            component={ForgetPasswordPage}
          />
          <Route
            path={routes.REGISTRATION_ROUTE}
            component={RegistrationPage}
          />
          <Route
            path={routes.CONFIRMATION_ROUTE}
            component={ConfirmationPage}
          />
          <Route
            path={routes.VERIFY_ACCOUNT_ROUTE}
            component={VerificationPage}
          />

          <Route path={routes.HOME_ROUTE} component={HomePage} />

          <Route path={routes.CHAPTERS_ROUTE} component={ChaptersPage} />

          <Route
            path={routes.ASSIGNMENT_PREFERENCES_ROUTE}
            component={AssignmentPreferences}
          />

          <Route path={routes.PAYMENTS_ROUTE} component={Payments} />

          <Route path={routes.PRACTICE_MODE_ROUTE} component={PracticeMode} />

          <Route path={routes.SOCIAL_MODE_ROUTE} component={SocialMode} />
          <Redirect
            from={routes.SOCIAL_MODE_REDIRECT}
            to={routes.SOCIAL_MODE_ROUTE}
          />
          <Route path={routes.PUBLISHERS_ROUTE} component={PublishersPage} />
          <Route path={routes.SUPPORT_ROUTE} component={SupportPage} />
          <Route path={routes.PROFILE_ROUTE} component={ProfilePage} />

          <Route path="/_/theme" component={AppTheme} />

          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
      <GlobalStyle />
    </React.Fragment>
  );
};

App.propTypes = {
  intl: PropTypes.shape().isRequired,
};
export default injectIntl(App);
