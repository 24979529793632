/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 */

import { addLocaleData } from 'react-intl';

import { APP_LOCALES, APP_LOCALE_DEFAULT } from './utils/constants';

import locales from './locales';
import translations from './translations';

export const DEFAULT_LOCALE = APP_LOCALE_DEFAULT;

export const appLocales = APP_LOCALES;

APP_LOCALES.forEach(locale => addLocaleData(locales[locale]));

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, translations[DEFAULT_LOCALE])
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = APP_LOCALES.reduce(
  (acc, locale) => ({
    ...acc,
    [locale]: formatTranslationMessages(locale, translations[locale]),
  }),
  {},
);
