/**
 * This file makes sure sentry is loaded.
 */
import React from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE || process.env.NODE_ENV,
  });
} else {
  // eslint-disable-next-line no-console
  console.warn('Sentry config not loaded, make sure you set the correct DSN!');
}

class SentryErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.entries(errorInfo).forEach(([key, extra]) =>
        scope.setExtra(key, extra),
      );
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

SentryErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export { Sentry, SentryErrorBoundary };
