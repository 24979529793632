import { css } from 'styled-components';
import Color from 'color';

import { ASSIGNMENT_MODE } from 'utils/constants';

import BackgroundPatternPractice from 'images/mode-practice-bg.png';
import BackgroundPatternSocial from 'images/mode-social-bg.png';
import BackgroundPatternCompetition from 'images/mode-competition-bg.png';

// CSS color naming convention as per https://codepen.io/meodai/full/mEvZRx

export const color = {
  lemonChrome: '#ffc504',
  white: '#ffffff',
  compactDiscGrey: '#cdcdcd',
  congLuGreen: '#04c883',
  tianLanSky: '#5cb0ff',
  crashPink: '#ca83f2',
  richLavender: '#a65ccf',
  greenTeal: '#03b375',
  brilliantAzure: '#3c9df9',
  frostGrey: '#838383',
  turquoise: '#067e8e',
  denim: '#009EE3',
  frills: '#91a7c3',
  wallsOfSantorini: '#e9edf3',
  jet: '#343434',
  reiklandFleshshade: '#ca6750',
  denimTradition: '#7e96b4',
  black: '#000000',
  appleGreen: '#7ed321',
  citrusNotes: '#d86048',
  shadowedSteel: '#4a4a4a',
  sparklingFrost: '#d0d5db',
  kachiIndigo: '#3a3f4f',
  noqreiSilver: '#bcbebd',
  scarabaeusSacer: '#404040',
  silverLake: '#dedede',
  leadbelcher: '#cacaca',
  boysenberryShadow: '#f2f4f8',
  zhenZhuBaiPearl: '#f6f8fa',
  lightAzure: '#99ccf3',
  astralSpirit: '#8fc4ed',
  peony: '#ec9aa4',
  lightGreenVeil: '#e3f5cf',
  bloodOrange: '#d0021b',
  lightCandela: '#c8d2e0',
  cloudAbyss: '#dee5ed',
  fuchsiaBlue: '#845ec1',
  aquaZing: '#74d8d7',
  shadowGargoyle: '#686868',
  yoshiGreen: '#57a700',
  oldCheddar: '#d86048',
  brightNavyBlue: '#1976d2',
  carnation: '#ff6f91',
  quackQuack: '#ffea89',
};

color.ACCENT_PRIMARY =
  process.env.REACT_APP_THEME_ACCENT_PRIMARY_COLOR || color.tianLanSky;
color.ACCENT_SECONDARY =
  process.env.REACT_APP_THEME_ACCENT_SECONDARY_COLOR || color.brilliantAzure;

color.HIGHLIGHT_BACKGROUND =
  process.env.REACT_APP_THEME_HIGHLIGHT_BACKGROUND_COLOR ||
  color.zhenZhuBaiPearl;

color.SUCCESS = color.congLuGreen;

color.FAILURE = color.citrusNotes;

color.ANSWERS = [
  color.lemonChrome,
  color.astralSpirit,
  color.congLuGreen,
  color.richLavender,
  color.peony,
  color.reiklandFleshshade,
  color.denimTradition,
];

export function alpha(baseColor, alphaValue) {
  return Color(baseColor)
    .alpha(alphaValue)
    .rgb()
    .string();
}

export const breakpoints = {
  very_small: 375,
  small: 480,
  medium: 720,
  large: 1024,
  very_large: 1280,
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)}
    }
  `;

  acc[`not_${label}`] = (...args) => css`
    @media (max-width: ${breakpoints[label] - 1}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const modeTheme = {
  [ASSIGNMENT_MODE.PRACTICE]: {
    primaryColor:
      process.env.REACT_APP_THEME_PRACTICE_MODE_PRIMARY_COLOR ||
      color.congLuGreen,
    secondaryColor:
      process.env.REACT_APP_THEME_PRACTICE_MODE_SECONDARY_COLOR ||
      color.greenTeal,
    backgroundPattern: BackgroundPatternPractice,
  },
  [ASSIGNMENT_MODE.SOCIAL]: {
    primaryColor:
      process.env.REACT_APP_THEME_SOCIAL_MODE_PRIMARY_COLOR || color.tianLanSky,
    secondaryColor:
      process.env.REACT_APP_THEME_SOCIAL_MODE_SECONDARY_COLOR ||
      color.brilliantAzure,
    backgroundPattern: BackgroundPatternSocial,
  },
  [ASSIGNMENT_MODE.COMPETITION]: {
    primaryColor:
      process.env.REACT_APP_THEME_COMPETITION_MODE_PRIMARY_COLOR ||
      color.crashPink,
    secondaryColor:
      process.env.REACT_APP_THEME_COMPETITION_MODE_SECONDARY_COLOR ||
      color.richLavender,
    backgroundPattern: BackgroundPatternCompetition,
  },
};

export const backgroundTheme = {
  bgPrimaryColor:
    process.env.REACT_APP_THEME_BACKGROUND_PRIMARY_COLOR || color.carnation,
  bgSecondaryColor:
    process.env.REACT_APP_THEME_BACKGROUND_SECONDARY_COLOR || color.quackQuack,
  fontPrimaryColor:
    process.env.REACT_APP_THEME_FONT_PRIMARY_COLOR || color.fuchsiaBlue,
  fontSecondaryColor:
    process.env.REACT_APP_THEME_FONT_SECONDARY_COLOR || color.denim,
};
