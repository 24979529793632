import * as PropTypes from 'prop-types';

import {
  LANGUAGE_CODE,
  USER_ROLE,
  USER_GENDER,
  ASSIGNMENT_MODE,
  ASSIGNMENT_COMPETENCE_ID,
  PARTY_ROOM_STATE,
  PAYMENT_STATUS,
  MEDIA_TYPE,
} from './constants';

export * from 'prop-types';

/**
 * General Purpose
 */

/**
 * Routing
 */

const match = PropTypes.shape({
  isExact: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string, // only in createBrowserHistory and createMemoryHistory
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]), // only in createBrowserHistory and createMemoryHistory
});

const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func, // only in createMemoryHistory
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(location), // only in createMemoryHistory
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number, // only in createMemoryHistory
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

export const withRouting = {
  match: match.isRequired,
  location: location.isRequired,
  history: history.isRequired,
};

/**
 * State Management
 */

export const withState = {
  dispatch: PropTypes.func.isRequired,
};

/**
 * React Intl
 */

export const withIntl = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * Domain Entities
 */

export const ID = PropTypes.string;

export const FirebaseTimestamp = PropTypes.object;

export const LanguageCode = PropTypes.oneOf(Object.values(LANGUAGE_CODE));

export const mediaType = PropTypes.oneOf(Object.values(MEDIA_TYPE));

export const UserRole = PropTypes.oneOf(Object.values(USER_ROLE));

export const UserGender = PropTypes.oneOf(Object.values(USER_GENDER));

export const UserAccount = PropTypes.shape({
  id: ID.isRequired,
  role: UserRole.isRequired,
  displayName: PropTypes.string.isRequired,
  photoURL: PropTypes.string,
  email: PropTypes.string,
  emailVerified: PropTypes.bool.isRequired,
});

export const UserProfile = PropTypes.shape({
  language: LanguageCode,
  isLoudlyVerificationDone: PropTypes.bool,
  isSchoolInformationUpdated: PropTypes.bool,
  isProfileInformationUpdated: PropTypes.bool,
  name: PropTypes.string,
  birthYear: PropTypes.string,
  classroom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  country: PropTypes.string,
  gender: UserGender,
  grade: PropTypes.number,
  level: PropTypes.string,
  schoolId: ID,
  schoolName: PropTypes.string,
  ItslProfileId: PropTypes.number,
  boughtChapterIds: PropTypes.arrayOf(ID),
});

export const Question = PropTypes.shape({
  id: ID.isRequired,
  question: PropTypes.string,
  questionMediaType: mediaType,
  questionMedia: PropTypes.string,
  answer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  movingMedia: PropTypes.string,
  staticMedia: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      choice: PropTypes.string,
      media: PropTypes.string,
      mediaType,
    }),
  ),
  keys: PropTypes.arrayOf(PropTypes.string),
});

export const Wordlist = PropTypes.shape({
  id: ID.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(Question),
  questionsCount: PropTypes.number,
});

export const Chapter = PropTypes.shape({
  id: ID.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  wordlists: PropTypes.arrayOf(Wordlist),
  wordlistsCount: PropTypes.number,
});

export const Method = PropTypes.shape({
  id: ID.isRequired,
  name: PropTypes.string.isRequired,
  publisher: PropTypes.string.isRequired,
  methodLanguage: LanguageCode.isRequired,
  sourceLanguage: LanguageCode.isRequired,
  targetLanguage: LanguageCode.isRequired,
  icon: PropTypes.string.isRequired,
  chapters: PropTypes.arrayOf(Chapter),
  chaptersCount: PropTypes.number,
});

export const CompetenceId = PropTypes.oneOf(
  Object.values(ASSIGNMENT_COMPETENCE_ID),
);

const ExerciseProps = {
  methodId: PropTypes.string.isRequired,
  chapterId: PropTypes.string.isRequired,
  wordlistId: PropTypes.string.isRequired,

  sourceLanguage: LanguageCode.isRequired,
  targetLanguage: LanguageCode.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,

  mode: PropTypes.oneOf(Object.values(ASSIGNMENT_MODE)).isRequired,
  competenceId: CompetenceId.isRequired,

  showCorrectAnswer: PropTypes.bool,
  checkUpperLowerCase: PropTypes.bool,
  checkPunctuation: PropTypes.bool,
  answerWithinTimeLimit: PropTypes.bool,
  answerTimeLimit: PropTypes.number,
  scoreIsTimeDependent: PropTypes.bool,
  automaticSpeechAssessment: PropTypes.bool,
  practiceOnlyWrongWords: PropTypes.bool,
  practiceUntilCorrect: PropTypes.bool,
};

export const Exercise = PropTypes.shape(ExerciseProps);

export const Competence = PropTypes.shape({
  id: CompetenceId.isRequired,
  title: PropTypes.shape().isRequired,
  isEnabled: PropTypes.bool.isRequired,
});

export const PartyRoomPlayer = PropTypes.shape({
  id: ID,
  displayName: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  joinedAt: FirebaseTimestamp,
});

export const PartyRoomPlayerAnswer = PropTypes.shape({
  playerId: ID.isRequired,
  questionId: ID.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  answer: PropTypes.any.isRequired,
  answerIsCorrect: PropTypes.bool.isRequired,
  timestamp: FirebaseTimestamp,
});

export const PartyRoomPlayerRank = PropTypes.shape({
  playerId: ID,
  correctAnswersCount: PropTypes.number,
  wrongAnswersCount: PropTypes.number,
  rank: PropTypes.number,
});

export const PartyRoomBaseProps = {
  id: ID.isRequired,
  state: PropTypes.oneOf(Object.values(PARTY_ROOM_STATE)).isRequired,
  createdAt: FirebaseTimestamp.isRequired,
  startedAt: FirebaseTimestamp,
  closedAt: FirebaseTimestamp,
  organizerId: ID.isRequired,
  playerIds: PropTypes.arrayOf(ID).isRequired,
  playerById: PropTypes.objectOf(PartyRoomPlayer).isRequired,
  questionIds: PropTypes.arrayOf(ID).isRequired,
  questionTimestampById: PropTypes.objectOf(FirebaseTimestamp).isRequired,
  activeQuestionId: ID,
  playerAnswerByPlayerIdByQuestionId: PropTypes.objectOf(
    PropTypes.objectOf(PartyRoomPlayerAnswer),
  ).isRequired,
  playerRankById: PropTypes.objectOf(PartyRoomPlayerRank).isRequired,
};

const PartyRoomComputedProps = {
  playerListComputed: PropTypes.arrayOf(PartyRoomPlayer).isRequired,
  activeQuestionComputed: Question,
  activeQuestionIndexComputed: PropTypes.number,
  playerRankListComputed: PropTypes.arrayOf(PartyRoomPlayerRank).isRequired,
};

export const PartyRoom = PropTypes.shape({
  ...ExerciseProps,
  ...PartyRoomBaseProps,
  ...PartyRoomComputedProps,
});

export const Order = PropTypes.shape({
  id: ID.isRequired,
  userId: ID.isRequired,
  methodId: ID.isRequired,
  chapterId: ID.isRequired,
  paymentId: ID.isRequired,
  paymentStatus: PropTypes.oneOf(Object.values(PAYMENT_STATUS)).isRequired,
  paymentAmount: PropTypes.number.isRequired,
  paymentCreatedAt: PropTypes.string.isRequired,
  paymentDescription: PropTypes.string.isRequired,
});

export const Student = PropTypes.shape({
  id: ID.isRequired,
  name: PropTypes.string.isRequired,
});
